form {
	@include paragraph-margins;
/*    @include breakpoint(bp-medium) {
        margin: ($bp-m-base-line-height)+em 0;
    }*/
}

// .form-group module (containing label, input, paddings and margin) 
// Mobile: takes up 89.6px (= 4 times our BUoVR 22.4). 
// Breakdown is: 33.6 (BUoVR * 0.75 = 16.8 + 16.8) in margin; 22.4 for label; zero vertical padding; 31.6 for input line-height; and 2px (1+1 input borders) 

button, 
.btn, 
input,
optgroup,
select,
textarea {
    font-family: $font-sans;
    line-height: (31.6/16)+em;
    /*@include breakpoint(bp-medium) {
        line-height: (37/$bp-m-base-font-size)+em; // =37/18
    }*/
}

.form-group {
    display: block;
    position: relative;
    clear: both;
    margin: ($base-line-height*.75)+em 0;
}

.label-text {
	display: block;
	font-weight: 600;
	text-transform: uppercase;
	@include font-size-to-rem(14);
    @include rhythmic-line-height(14);
}

input[type="text"], 
input[type="search"], 
input[type="url"], 
input[type="password"], 
input[type="email"], 
input[type="tel"], 
textarea {
    width: 100%;
    padding: 0 .5em;
}

input[type="text"], 
input[type="search"], 
input[type="url"], 
input[type="password"], 
input[type="email"], 
input[type="tel"], 
textarea, 
input[type="number"], 
.custom-select {
    border: 1px solid #bbb;
}

select {
    width: 100%;
}

// .custom-select module (containing label, input, paddings and margin) 
// Mobile: to match other inputs, .custom-select height needs to be: 31.6

.custom-select {
    /*box-shadow: 0 1px 0 1px rgba(0,0,0,.04);*/
    position: relative;
    display: block;
    select {
        appearance: none;
        background: none;
        box-sizing: border-box;
        border: 1px solid transparent;
        @include font-size-to-rem($base-font-size);
        line-height: $base-line-height;
        margin: 0;
        outline: none;
        padding: (4.2/16)+em 2em (3/16)+em .4em; // effect on height is 7.2 + 22.4 + 2 = 31.6
        /*@include breakpoint(bp-medium) {
            @include font-size-to-rem($bp-m-base-font-size, $bp-m-base-font-size);
            padding: 5px 2em 3px .4em;
        }*/
    }
    option {
        font-weight: normal;
    }
    &::after {
        // create the triangular “drop-down” icon
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 7px solid #666;
        content: " ";
        display: block;
        margin-top: -3px;
        position: absolute;
        right: 1em;
        top: 50%;
        pointer-events: none;
        width: 0;
        height: 0;
        z-index: 2;
    }
}

// button/.btn
// Mobile: 44.8=22.4+(padding: 5.6+5.6)+(margin: 5.6+5.6)
// Medium:

button, 
.btn {
    /*border-radius: 2em;*/
    border: none;
    background-color: $color-accent; //$color-main
    color: #fff;
    cursor: pointer;
    font-weight: 700;
    text-transform: uppercase;
    @include font-size-to-rem(14);
    @include rhythmic-line-height(14);
	padding: (5.6 / 14)+em 3em; // /*padding: .75em 3em;*/
    margin:  (5.6 / 14)+em 0;
    text-align: center;
    &:hover {
		background-color: darken($color-accent, 20%);
    }
    
}





// Comment forms

.comment-form {
	display: none;
}





