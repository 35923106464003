/*
Thin (normally 100)
Extra Light  (normally 200)
Light (normally 300)
Book  (?)
Regular (normally 400)
Core (?)
Medium  (normally 500)
SemiBold (normally 600)
Bold (normally 700)
ExtraBold (normally 800)
Heavy (normally 900)
*/

@font-face {
    font-family: 'FSEmeric';
    src: url('/fonts/fsemericwebextralight/FSEmericWeb-ExtraLight.eot'); /* IE9 */
    src: url('/fonts/fsemericwebextralight/FSEmericWeb-ExtraLight.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ 
    url('/fonts/fsemericwebextralight/FSEmericWeb-ExtraLight.woff') format('woff'); /* Modern Browsers */
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'FSEmeric';
    src: url('/fonts/fsemericwebextralightitalic/FSEmericWeb-ExtraLightItalic.eot'); /* IE9 */
    src: url('/fonts/fsemericwebextralightitalic/FSEmericWeb-ExtraLightItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ 
    url('/fonts/fsemericwebextralightitalic/FSEmericWeb-ExtraLightItalic.woff') format('woff'); /* Modern Browsers */
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'FSEmeric';
    src: url('/fonts/fsemericwebbook/FSEmericWeb-Book.eot');
    src: url('/fonts/fsemericwebbook/FSEmericWeb-Book.eot?#iefix') format('embedded-opentype'),
    url('/fonts/fsemericwebbook/FSEmericWeb-Book.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'FSEmeric';
    src: url('/fonts/fsemericwebbookitalic/FSEmericWeb-BookItalic.eot');
    src: url('/fonts/fsemericwebbookitalic/FSEmericWeb-BookItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/fsemericwebbookitalic/FSEmericWeb-BookItalic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

/*@font-face {
    font-family: 'FSEmeric';
    src: url('/fonts/fsemericwebregular/FSEmericWeb-Regular.eot');
    src: url('/fonts/fsemericwebregular/FSEmericWeb-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/fsemericwebregular/FSEmericWeb-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'FSEmeric';
    src: url('/fonts/fsemericwebitalic/FSEmericWeb-Italic.eot');
    src: url('/fonts/fsemericwebitalic/FSEmericWeb-Italic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/fsemericwebitalic/FSEmericWeb-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}*/

@font-face {
    font-family: 'FSEmeric';
    src: url('/fonts/fsemericwebsemibold/FSEmericWeb-SemiBold.eot'); /* IE9 */
    src: url('/fonts/fsemericwebsemibold/FSEmericWeb-SemiBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ 
    url('/fonts/fsemericwebsemibold/FSEmericWeb-SemiBold.woff') format('woff'); /* Modern Browsers */
    font-weight: 600; 
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'FSEmeric';
    src: url('/fonts/fsemericwebsemibolditalic/FSEmericWeb-SemiBoldItalic.eot'); /* IE9 */
    src: url('/fonts/fsemericwebsemibolditalic/FSEmericWeb-SemiBoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ 
    url('/fonts/fsemericwebsemibolditalic/FSEmericWeb-SemiBoldItalic.woff') format('woff'); /* Modern Browsers */
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'FSEmeric';
    src: url('/fonts/fsemericwebbold/FSEmericWeb-Bold.eot'); /* IE9 */
    src: url('/fonts/fsemericwebbold/FSEmericWeb-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ 
    url('/fonts/fsemericwebbold/FSEmericWeb-Bold.woff') format('woff'); /* Modern Browsers */
    font-weight: 700; 
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'FSEmeric';
    src: url('/fonts/fsemericwebbolditalic/FSEmericWeb-BoldItalic.eot'); /* IE9 */
    src: url('/fonts/fsemericwebbolditalic/FSEmericWeb-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ 
    url('/fonts/fsemericwebbolditalic/FSEmericWeb-BoldItalic.woff') format('woff'); /* Modern Browsers */
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

html {
    color: $color-main;
    font-family: $font-sans; //sans-serif;
    @include breakpoint(bp-medium) {
        font-size: 112.5%; // =1.125em (so 1rem (and 1em on any element without a dedicated font-size) = 18px)
    }
    /*@include breakpoint(bp-large) {
        font-size: 131.25%; // =1.3125em (so 1rem (and 1em on any element without a dedicated font-size) = 21px) | 21 rather than 22 because 22 is not on our scale.
    }*/
}

body {
    font-feature-settings: "liga" 1;
    font-feature-settings: "onum" 1;
    line-height: $base-line-height; // 16 * 1.5 = 24;
    @include breakpoint(bp-medium) {
        line-height: $bp-m-base-line-height;  // still 1.5 so at bp-medium and above, line-height = 27 (18 * 1.5) and at bp-large line-height = 31.5 (21 * 1.35);
    }
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

@supports (font-variant-ligatures: common-ligatures) {
    body {
        font-feature-settings: normal;
        font-variant-ligatures: common-ligatures;
    }
}

@supports (font-variant-numeric: oldstyle-nums) {
    body {
        font-feature-settings: normal;
        font-variant-numeric: oldstyle-nums;
    }
}

a {
    color: $color-accent; 
    text-decoration-skip-ink: none;
    &:hover {
        color: darken($color-accent, 20%);
        text-decoration: underline;
    }
}

small {
    @include font-size-to-rem($smallest-font-size);
    @include breakpoint(bp-medium) {
        @include font-size-to-rem($smallest-font-size);
    }
    @include breakpoint(bp-large) {
        @include font-size-to-rem($smallest-font-size);
    }
}

p {
    @include paragraph-margins();
    font-weight: 400;
    //max-width: 38em;
}

blockquote, 
ul,
ol, 
dl {
    @include paragraph-margins();
}

ul ul, 
ol ol {
    margin-top: 0;
}

li {
    list-style-position: inside;
}

ul li {
    padding-left: $base-line-height/2+em;
    li {
        padding-left: $base-line-height+em;
    }
}

@include breakpoint(bp-medium) {
    ol {
        list-style: none;
        counter-reset: mylist;
        li {
            list-style-position: outside;
            &::before {
                counter-increment: mylist;
                content: counter(mylist);
                margin-left: -1.25+em;
                margin-right: .75em;
            }
        }
    }
}

dt {
    font-weight: 600;
    margin: $base-line-height/2+em 0 0;
}

dd {
    margin-bottom: $base-line-height/2+em;
}

abbr, code.markup.element, code.markup.attribute {
    text-decoration: none;
    font-family: inherit;
    font-variant: all-small-caps;
}

// Headings, starting with smallest first.

h1, h2, h3, h4, h5, h6 {
    color: $color-interface-and-display;
    font-family: $font-sans;
    font-feature-settings: "dlig" 1;
    @supports (font-variant-ligatures: discretionary-ligatures) {
        .dlig {
            font-feature-settings: normal;
            font-variant-ligatures: discretionary-ligatures;
        }
    }
}

h6,
h5, 
h4 {
    font-weight: 200;
    @include font-size-to-rem($base-font-size);
    @include rhythmic-line-height($base-font-size);
    @include rhythmic-vertical-margins($base-font-size);
    @include breakpoint(bp-medium) {
        @include font-size-to-rem($bp-m-base-font-size, $bp-m-base-font-size);
        line-height: $bp-m-base-line-height;
        @include rhythmic-vertical-margins($bp-m-base-font-size, $bp-m-px-base-line-height);
    }
    /*@include breakpoint(bp-large) {
        @include font-size-to-rem($bp-l-base-font-size, $bp-l-base-font-size);
        line-height: $bp-l-base-line-height;
        @include rhythmic-vertical-margins($bp-l-base-font-size, $bp-l-px-base-line-height);
    }*/
}

%h3, 
h3 {
    font-weight: 200;
    /*letter-spacing: -0.02em;*/
    @include font-size-to-rem(21);
    @include rhythmic-vertical-margins(21);
    @include rhythmic-line-height(21);
    @include breakpoint(bp-medium) {
        @include font-size-to-rem(25, $bp-m-base-font-size);
        @include rhythmic-line-height(25, $bp-m-px-base-line-height);
        @include rhythmic-vertical-margins(25, $bp-m-px-base-line-height);
    }
    /*
    @include breakpoint(bp-large) {
        @include font-size-to-rem(28, $bp-l-base-font-size);
        @include rhythmic-line-height(28, $bp-l-px-base-line-height);
        @include rhythmic-vertical-margins(28, $bp-l-px-base-line-height);
    }*/
}

%h2, 
h2 {
    font-weight: 600;
    /*letter-spacing: -0.02em;*/
    @include font-size-to-rem(25);
    @include rhythmic-vertical-margins(25);
    line-height: 1; // @include rhythmic-line-height(25);
    @include breakpoint(bp-medium) {
        @include font-size-to-rem(33, $bp-m-base-font-size);
        line-height: 1; // @include rhythmic-line-height(33, $bp-m-px-base-line-height);
        @include rhythmic-vertical-margins(33, $bp-m-px-base-line-height);
    }
/*    
    @include breakpoint(bp-xlarge) {
        @include font-size-to-rem(44, $bp-l-base-font-size);
        //@include rhythmic-line-height(44, $bp-l-px-base-line-height * 1.75);

        line-height: 1;

        @include rhythmic-vertical-margins(44, $bp-l-px-base-line-height);
    }*/
}

%h1, 
h1 {
    font-weight: 700;
    letter-spacing: -0.02em;
    @include font-size-to-rem(33);
    @include rhythmic-vertical-margins(33);
    line-height: 1; // @include rhythmic-line-height(33, $base-line-height-in-px * 2);
    margin-top: 0;
    @include breakpoint(bp-medium) {
        @include font-size-to-rem(51, $bp-m-base-font-size);
        line-height: 1; /*@include rhythmic-line-height(51, $bp-m-px-base-line-height * 3);*/
        @include rhythmic-vertical-margins(51, $bp-m-px-base-line-height);
        margin-top: 0;
    }
/*    @include breakpoint(bp-medium) {
        @include font-size-to-rem(51, $bp-m-base-font-size);
        //@include rhythmic-line-height(51, $bp-m-px-base-line-height * 2.25);
        line-height: 1;
        @include rhythmic-vertical-margins(51, $bp-m-px-base-line-height);
    }
    @include breakpoint(bp-xlarge) {
        @include font-size-to-rem(78, $bp-l-base-font-size);
        //@include rhythmic-line-height(78, $bp-m-px-base-line-height * 3.25);
        line-height: 1;
        @include rhythmic-vertical-margins(78, $bp-l-px-base-line-height);
    }*/
    a {
        color: $color-interface-and-display;
        text-decoration: none;
    }
}

[role=contentinfo] {
    small {
        display: block;
        @include rhythmic-line-height($smallest-font-size);
        @include rhythmic-vertical-margins($smallest-font-size);
        @include breakpoint(bp-medium) {
            @include font-size-to-rem($smallest-font-size, $bp-m-base-font-size);
            @include rhythmic-line-height($smallest-font-size, $bp-m-px-base-line-height);
            @include rhythmic-vertical-margins($smallest-font-size, $bp-m-px-base-line-height);
        }
        /*@include breakpoint(bp-large) {
            @include font-size-to-rem($bp-l-base-font-size, $bp-l-base-font-size);
            @include rhythmic-line-height($smallest-font-size, $bp-l-px-base-line-height);
            @include rhythmic-vertical-margins($smallest-font-size, $bp-l-px-base-line-height);
        }*/
    }
    ul {
        @include font-size-to-rem($smallest-font-size);
        @include rhythmic-vertical-margins($smallest-font-size);
        @include breakpoint(bp-medium) {
            @include font-size-to-rem($smallest-font-size, $bp-m-base-font-size);
            @include rhythmic-vertical-margins($smallest-font-size, $bp-m-px-base-line-height);
        }
    }
    li {
        @include rhythmic-line-height($smallest-font-size);
        @include breakpoint(bp-medium) {
            @include rhythmic-line-height($smallest-font-size, $bp-m-px-base-line-height);
        }
    }
}

blockquote { //.quoted
    p {
        font-style: italic;
        hanging-punctuation: first last;
        quotes: '“' '”' '‘' '’';
        &:first-of-type::before {
            content: open-quote;
            /*margin-left: -0.83ch;*/
        }
    }
    footer {
        .cite-and-pubdate {
            display: block;
        }
    }   
}

@supports(hanging-punctuation: first last) {
    blockquote p:first-of-type::before {
        margin-left: 0;
    }
}

blockquote p:last-of-type::after  {
    content: close-quote;
}

blockquote p:last-of-type {
    margin-bottom: 0;
}

.pullquote {
}

figure {
    @include paragraph-margins();
}

figcaption {
    @include font-size-to-rem($smallest-font-size);
    @include rhythmic-line-height($smallest-font-size);
    color: $color-lightest;
    /*margin: 0; //@include rhythmic-vertical-margins($smallest-font-size);*/
    /*@include breakpoint(bp-medium) {
        @include font-size-to-rem($bp-m-base-font-size, $bp-m-base-font-size);
        line-height: $bp-m-base-line-height;
        @include rhythmic-vertical-margins($bp-m-base-font-size, $bp-m-px-base-line-height);
    }
    @include breakpoint(bp-large) {
        @include font-size-to-rem($bp-l-base-font-size, $bp-l-base-font-size);
        line-height: $bp-l-base-line-height;
        @include rhythmic-vertical-margins($bp-l-base-font-size, $bp-l-px-base-line-height);
    }*/
}

article {
    p,
    li,
    dt,
    dd {
        hyphens: auto; // assuming we've set our language in the <html> element, this turn on automatic hyphenation.
    }
}

@supports(-webkit-initial-letter: 2) {
    .article-body::first-letter {
        -webkit-initial-letter: 2;
        initial-letter: 2;
        font-weight: bold;
        margin-right: .125em;
    }
}

.more-posts {
    display: block;
    @include paragraph-margins();
    @include rhythmic-line-height($smallest-font-size);
    @include rhythmic-vertical-margins($smallest-font-size);
    text-align: center;
}


// 

.date, 
%date, 
.meta
 {
    color: $color-lightest;
}


// Lists

.hfeed, 
.hfeed > li {
    list-style: none;
    padding-left: 0;
}

.hfeed .hentry {
    background: linear-gradient($color-accent-alt, $color-accent-alt) no-repeat bottom center / 2.4em 0.2ch;
    padding-bottom: $base-line-height*2+em;
    margin-bottom: $base-line-height*2+em;
    /*@include breakpoint(bp-medium) {
        margin-bottom: $bp-m-base-line-height*2+em;
    }
    @include breakpoint(bp-xlarge) {
        margin-bottom: $bp-l-base-line-height*2+em;
    }*/
    &:last-child {
        background: none;
    }
    h3 {
        @extend %h2;
        color: $color-accent;
    }
    img {
        display: block;
        margin: $base-line-height+em 0;
    }
    .date {
        @extend %date;
    }

    a { 
        text-decoration: none;
        
/*        .entry-published {
            color: $color-main;
        }*/
    } 
    .description p {
        color: $color-main;
        margin-bottom: 0;
    }
}



//

.intro {
    margin-bottom: $base-line-height*2+em;
    padding-bottom: $base-line-height+em;
    background: linear-gradient($color-main, $color-main) no-repeat bottom left / 2.4em 0.2ch;
    /*@include breakpoint(bp-medium) {
        margin-bottom: $bp-m-base-line-height*2+em;
    }*/
    /*@include breakpoint(bp-xlarge) {
        margin-bottom: $bp-l-base-line-height*2+em;
    }*/
    .intro-last-full-stop {
        color: $color-accent-alt;
    }
    p {
        color: $color-light-alt;
        font-weight: 200;
        @include font-size-to-rem(18);
        @include rhythmic-line-height(18);
        @include rhythmic-vertical-margins(18);
        /*@include breakpoint(bp-medium) {
            @include font-size-to-rem(21, $bp-m-base-font-size);
            @include rhythmic-line-height(21, $bp-m-px-base-line-height);
            @include rhythmic-vertical-margins(21, $bp-m-px-base-line-height);
        }
        @include breakpoint(bp-large) {
            @include font-size-to-rem(25, $bp-l-base-font-size);
            @include rhythmic-line-height(25, $bp-l-px-base-line-height);
            @include rhythmic-vertical-margins(25, $bp-l-px-base-line-height);
        }*/
    }
    a {
        /*color: inherit;*/
        text-decoration: none;
        /*text-decoration-line: underline;
        text-decoration-color: 1px solid lighten($color-main, 80%);*/
    }
}

