@import "normalise.scss";
@import "reset.scss";
@import "selected_h5bp_maindotcss_goodies.scss";
@import "variables.scss";
@import "mixins.scss";
@import "custom_general.scss";
@import "general_typography.scss";
@import "forms.scss";

// Responsive images

img {
    max-width: 100%;
}


// Tables
// For consistency and ease of understanding, match the alignment of headings to the alignment of the data.
// Right-align headings of numeric data and left-align headings of columns with text.

table {
    font-variant-numeric: lining-nums tabular-nums;
    @include paragraph-margins();
}
tfoot {
    display: none;
}
.fig-table {
    max-width: 100%;
    overflow-x: scroll;
}

// Alerts

%alert {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}
[role=alert],
.message,
.alert-success,
.alert-error {
    @extend %alert;
}
.alert-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}
.alert-error {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

// Tabs

[role=tab] {
    display: inline-block;
}
[role=tab][aria-selected=true] {
    background: tomato;
}
[role=tabpanel] {
    display: none;
}
[role=tabpanel][aria-expanded=true] {
    display: block;
}

// Utilities / Common

/* Embed Video and other media */

.embed-container {
    margin: $base-line-height+em 0;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.embed-container iframe, .embed-container object, .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Codepen embeds */
.cp_embed_wrapper {
    margin: $base-line-height+em 0;
}

// Body

body {
    display: grid;
    grid-template-areas:
        "header"
        "intro"
        "content"
        "sidebar"
        "footer";
    @media (min-width: 80em) {
        grid-template-columns: auto 32rem 14.4rem auto; //grid-template-columns: 1fr 1fr 33rem 12rem 1fr 1fr;
        grid-template-areas:
          "header header  header  header"
          ".      content sidebar .     "
          "footer footer  footer  footer";
        column-gap: 3em;
    }
}

// Global Header

[role=banner] {
    max-width: 32rem;
    margin: 0 auto;
    width: 90%;
    @media (min-width: 80em) {
        max-width: none;
        width: auto;
        margin: 0;
        grid-area: header;
        display: inherit;
        grid-template-columns: 1fr 1fr 32rem 14.4rem 1fr 1fr;
        column-gap: 3em;
    }
}

#logo {
    @include retinize('logo_roundel_96', 'png', 96px, 96px);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: block;
    text-indent: -9999999px;
    width: 96px;
    line-height: $base-line-height*4+em; //96px;
    margin: $base-line-height/2+em auto;
    @include breakpoint(bp-medium) {
        @include retinize('logo_roundel_108', 'png', 108px, 108px);
        width: 108px;
    }
    @media (min-width: 80em) {
        grid-column: 2 / 3;
    }
}

// Primary Navigation

[aria-labelledby="header-nav"] {
    @media (min-width: 80em) {
        grid-column: 3 / 5;
        align-self: center; // vertical alignment
        justify-self: end; // horizontal alignment
    }
    ul {
        list-style: none;
        margin: 0 0 $base-line-height+em 0;
        padding: 0;
        display: flex;
        justify-content: space-between;
        @media (min-width: 80em) {
            margin: 0;
            justify-content: flex-start;
        }
        li {
            margin: 0;
            padding: 0;
            @media (min-width: 80em) {
                margin-right: $base-line-height*2+em;
            }
        }
        li:last-child {
            @media (min-width: 80em) {
                margin-right: 0;
            }
        }
        a {
            display: block;
            text-transform: uppercase;
            text-decoration: none;
            font-weight: 700;
            @include font-size-to-rem(14);
            @include rhythmic-line-height(14);
            @include breakpoint(bp-medium) {
                @include font-size-to-rem(14, $bp-m-base-font-size);
                @include rhythmic-line-height(14, $bp-m-px-base-line-height);
            }
        }
    }
}
#nav-bytes {
    display: none;
    @include breakpoint(bp-medium) {
        display: block;
    }
}

// Main

main {
    background: linear-gradient(#eee, #eee) no-repeat bottom center / 2.4em 0.2ch;
    padding-bottom: $base-line-height*2+em;
    margin-bottom: $base-line-height*2+em;
    max-width: 32rem;
    margin: 0 auto;
    width: 90%;
    @media (min-width: 80em) {
        margin: 0;
        width: auto;
        grid-area: content;
    }
}

// Sidebar

[role=complementary] {
    color: $color-lightest;
    background: linear-gradient(#eee, #eee) no-repeat bottom center / 2.4em 0.2ch;
    padding: $base-line-height+em 0 $base-line-height*2+em;
    margin-bottom: $base-line-height*2+em;
    max-width: 32rem;
    margin: 0 auto;
    width: 90%;
    @media (min-width: 80em) {
        background: linear-gradient(#eee, #eee) no-repeat top left / 1px 100%;
        width: auto;
        grid-area: sidebar;
        padding: 0 0 0 $base-line-height+em;
        p {
            @include font-size-to-rem(16, $bp-m-base-font-size); // reduce the font-size from 18 to 16
            line-height: (24/16); // in the sidebar reduce the line-height from 27px to 24px, so 1.3333333333 (24/16)
            /*@include rhythmic-vertical-margins(16, 24);*/
            margin: 12px 0;
        }
        label {
            @include font-size-to-rem(16, $bp-m-base-font-size);
        }
        h3 {
            @include font-size-to-rem(14, $bp-m-base-font-size);
            line-height: (24/14); // in the sidebar reduce the line-height from 27px to 24px
            margin-top: 0;
            margin-bottom: 12px;
            text-transform: uppercase;
            font-weight: 700;
        }
    }
    a {
        color: $color-lightest;
    }
}


.sidebar-module {
    margin-bottom: $base-line-height*2+em;
    &:first-of-type h3 {
        margin-top: 0;
    }
}

// Footer (i.e. the global footer, as opposed to any other "local" <footer> elements)

[role=contentinfo] {
    color: $color-lightest;
    max-width: 32rem;
    margin: 0 auto;
    width: 90%;
    text-align: center;
    @media (min-width: 80em) {
        text-align: left;
        max-width: none;
        width: auto;
        margin: 0;
        grid-area: footer;
        display: inherit;
        grid-template-columns: 1fr 1fr 32rem 14.4rem 1fr 1fr;
        column-gap: 3em;
        small {
            grid-column: 2 / 4;
            order: 1;
        }
        nav {
            grid-column: 4 / 5;
            order: 2;
        }
    }
}

[aria-labelledby="footer-nav"] {
    ul {
        list-style: none;
        padding: 0;
        display: flex;
        justify-content: center;
        @media (min-width: 80em) {
            justify-content: flex-end;
        }
        li {
            margin: 0;
            padding: 0;
        }
    }
}

// Search Results

#search-results {
    list-style: none;
    li {
        padding: 0;
    }
}

// Comments

.comments {
  list-style: none;

  li {
    padding-left: 0;
  }

  img {
    display: none;
  }
}
