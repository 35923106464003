// Colours
//$color-main: rgb(68,68,68); //#333; // #444 (versus previous #333 (rgb(51,51,51)) which is darker)
//$color-interface-and-display: rgb(68,68,68); // #444 (versus previous #333 or rgb(51,51,51) which is darker)
//$color-accent: #35857d;

$color-main: rgb(34,34,34); //rgb(149,149,149);
$color-interface-and-display: rgb(34,34,34);
$color-accent: rgb(30,139,142);
$color-accent-alt: rgb(215,0,20);
$color-light: lighten($color-main, 25%);
$color-light-alt: lighten($color-main, 12.5%);
$color-lightest: rgb(149, 149, 149);




// Responsive Breakpoints
// Use ems rather than pixels to determine the screen width, so that:
// i) our layout adaptations are based on typographic reasoning rather than being tied to specific device widths
// ii) we've taken into account situations when the reader adjusted the default font size of their device. By using ems, our measurements scale proportionately with text size.
$bp-xxsmall:  23.4375em; // 375px, the width in portrait of iPhone 6/7/8
$bp-xsmall:  37.5em; // 600px, a boundary above which tall 3:4 images feel weird and we want to start using available width.
$bp-small:  48em; // 768px, tablet in portrait
$bp-medium:  60em; // 960px
$bp-large:  90em; // 1440px
$bp-xlarge:  120em; // 1920px

// Note: if we're adapting layout based on typographic reasoning rather than specific device widths, ....
// shouldn't we set breakpoints at the points at which our body column becomes wider than that which is needed for a comfortable measure?
// We want a line-length of 45–75 characters. On average 1em accounts for 2 characters so...
// A max-width of 38em on paragraphs of font-size 16px restricts line width to the top end of the readable range. 
// Arguably 40em (or just under) should be the first breakpoint. Then, you reduce the number of characters in a line by either increasing font-size or introducing additional columns. 




// Typeface choices
$font-sans: 'FSEmeric', sans-serif;
$font-serif: 'FSEmeric', sans-serif;


// Typographic Scale
// http://simplescale.online/?scale_base=16&scale_ratio=1.333&scale_interval=2
// and 
// https://www.modularscale.com/?16,138&px&1.333



// Key Font Size Settings
// We're using this typographic scale: http://simplescale.online/...
// with a ratio of X (decimal equivalent of Y) to work well with Z
// we start with body copy font size at X, with a line height of Y
// at viewport width X+ we increase body font size to Y
// at viewport width X+ we increase body font size to Y
$smallest-font-size: 12;
$base-font-size: 16;
$base-line-height: 1.5;
$base-line-height-in-px: ($base-font-size * $base-line-height);

$bp-xs-base-font-size: 16;
$bp-xs-base-line-height: 1.5;
$bp-xs-px-base-line-height: ($bp-xs-base-font-size * $bp-xs-base-line-height);

$bp-s-base-font-size: 16;
$bp-s-base-line-height: 1.5;
$bp-s-px-base-line-height: ($bp-s-base-font-size * $bp-s-base-line-height);

$bp-m-base-font-size: 18;
$bp-m-base-line-height: 1.5;
$bp-m-px-base-line-height: ($bp-m-base-font-size * $bp-m-base-line-height);

$bp-l-base-font-size: 21;
$bp-l-base-line-height: 1.5;
$bp-l-px-base-line-height: ($bp-l-base-font-size * $bp-l-base-line-height);

$bp-xl-base-font-size: 21;
$bp-xl-base-line-height: 1.5;
$bp-xl-px-base-line-height: ($bp-xl-base-font-size * $bp-xl-base-line-height);
